.tab_list_style {
  color: grey;
}

.tab_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 70px;
}

.tab_style {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-left: 22px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  height: 55px;
  border: none;
  border-color: transparent;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 0.95em;
  margin: 0px 0px;
  width: 100%;
  align-items: center;
}

.tab_style:focus {
  outline: none;
}

.tab_style:hover {
  background-color: var(--app-offwhite);
}

.child_tab_style:hover {
  margin-left: -4px;
  padding-left: 26px;
  width: calc(100% + 4px);
}

.focused_tab_style::before {
  content: "";
  position: relative;
  color: transparent;
  left: -18px;
  background-color: transparent;
  height: 40px;
  border: none;
  margin-left: -2px;
  border-right: 4px solid var(--app-blue);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
}

.focused_tab_style {
  border-color: var(--app-blue);
  color: var(--app-blue);
  padding-left: 20px;
  font-weight: bold;
}

.focused_tab_children_style {
  color: var(--app-blue);
  font-weight: bold;
}

.tab_style a {
  padding-left: 25px;
  margin-left: -25px;
}

.collapse_icon_container {
  position: absolute;
  right: -12px;
  top: 5px;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
