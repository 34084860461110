.edit_banner_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--app-blue);
  background-color: var(--app-lightblue);
  border-radius: 5px;
  padding: 8px 10px;
  row-gap: 5px;
  column-gap: 10px;
}

.edit_banner_title_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.edit_banner_title_text {
  font-weight: 500;
}

.edit_banner_rename_action_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.edit_banner_rename_input_field {
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  font-size: 15px;
  padding: 4px 5px 0px 5px;
  width: 200px;
}

.edit_banner_rename_action_button {
  cursor: pointer;
}

.edit_banner_rename_action_button:hover {
  opacity: 0.6;
}

.edit_banner_action_buttons_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 15px;
  row-gap: 5px;
}

.edit_banner_action_button {
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
  color: var(--app-blue);
}

.edit_banner_action_button_delete {
  color: var(--status-red);
}

.edit_banner_action_button:hover {
  text-decoration: underline;
}
