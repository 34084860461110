.action_bar {
  display: flex;
  background-color: var(--app-lightblue);
  font-weight: 500;
  font-size: 13px;
  border-radius: 3px;
  padding: 3px;
}

.selection_info {
  display: flex;
  align-items: center;
  margin-right: 35px;
  margin-left: 15px;
}

.multi_select_options {
  display: flex;
  flex: 1;
}

.selected_amount {
  font-weight: 700;
  font-size: 15px;
  margin-right: 5px;
}

.selected_text {
  margin-right: 20px;
}

.multi_select_option {
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.action_bar_button {
  color: var(--app-blue);
  cursor: pointer;
  padding: 5px;
}

/* .action_icon {
  margin-right: 5px;
} */

.multi_select_option_text {
  margin-left: 5px;
  font-weight: 600;
}
