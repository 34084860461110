.flex_row {
  display: flex;
  flex-direction: row;
}

.table_item {
  flex: 1;
  word-break: break-all;
  padding: 14px 0 14px 0;
  align-self: center;
}

.table_row {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;
}

.link_text {
  text-decoration: underline;
  color: var(--app-blue);
  cursor: pointer;
}
