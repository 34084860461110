.status_container {
    border-radius: 15px;
    margin: 0px 0px 0px 0px;
    vertical-align: middle;
    justify-self: left;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 24px;
    font-weight: bold;
}

.status_text {
    padding: 10px;
}

.status_match {
    background: var(--status-green);
    color: var(--app-white);
}

.status_incomplete {
    background: var(--neutral-primary);
    color: var(--app-white);
}

.status_partial {
    background: var(--status-yellow);
    color: var(--app-lightblack);
}

.status_extra {
    background: var(--status-red);
    color: var(--app-white);
}