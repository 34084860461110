.flex_column {
  display: flex;
  flex-direction: column;
}

.title_container {
  gap: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.title {
  font-weight: bold;
  font-size: 20px;
}

.sub_title {
  font-weight: 500;
}

.status_text {
  margin-left: 10px;
}
