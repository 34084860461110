.detail_img {
  max-height: 160px;
}

.top_group {
  flex-direction: row;
  display: flex;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid var(--app-border-grey);
}

.title_group {
  width: 90%;
  padding: 5px 5px 5px 10%;
}

.edit_icon {
  position: relative;
  right: -10px;
  cursor: pointer;
  border-radius: 50%;
  padding: 3px;
  background-color: var(--app-white);
  color: var(--neutral-primary);
}

.edit_icon:hover {
  background: var(--app-blue);
  color: var(--app-white);
}

.title_label {
  font-size: 16px;
}

.title_value {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 5px;
  border: none;
  resize: none;
  background: transparent;
  width: 90%;
  overflow: hidden;
}

.title_value_input {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
  resize: none;
  overflow: hidden;
}
.title_value_input:focus {
  border: 1px solid var(--app-blue);
}

.bottom_group {
  margin-top: 10px;
  padding: 5px;
}

.detail_group {
  min-height: 5px;
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  line-height: 20px;
}

.detail_label {
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
  flex: 0.4;
  text-align: right;
}

.detail_value {
  font-size: 14px;
  text-align: left;
  resize: none;
  border: none;
  background: transparent;
  overflow: visible;
  flex: 0.6;
  padding: 0;
}

.detail_value_input {
  height: 25px;
  font-size: 12px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 0.6;
  overflow: hidden;
}

.date_time_value {
  font-size: 14px;
  text-align: left;
  resize: none;
  border: none;
  background: transparent;
  overflow: visible;
  flex: 1;
  overflow: hidden;
  padding: 0;
}

.date_time_value_input {
  height: 25px;
  width: 140px;
  font-size: 12px;
  text-align: left;
  resize: none;
  border: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 1;
  overflow: hidden;
}

.date_time_with_clear {
  height: 25px;
  width: 170px;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 1;
  overflow: hidden;
}

.clear_icon {
  position: relative;
  margin-top: 0%;
  margin-left: 5%;
  resize: none;
  border-radius: 50%;
  background-color: var(--app-white);
  color: var(--neutral-primary);
  max-width: 10px;
  max-height: 10px;
}

.clear_icon:hover {
  cursor: pointer;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.detail_value_input:focus {
  border: 1px solid var(--app-blue);
}

.cancel_btn:hover {
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

.save_btn:hover {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.25);
}

.react_switch {
  vertical-align: middle;
  text-align: left;
  flex: 0.6;
}

.date_time {
  display: flex;
  flex: 0.6;
  flex-direction: row;
  text-align: center;
}

.edit_form_buttons {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
}

.blue_btn {
  background: var(--app-blue);
  color: var(--app-white);
}

.gray_btn {
  background: var(--app-border-grey);
  color: var(--text-primary);
}

.white_btn {
  background: var(--app-white);
  color: var(--app-blue);
  border: 1px solid var(--app-blue);
}

.btn {
  text-transform: none;
  letter-spacing: 0.04rem;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 7px 30px 7px 30px;
  font-size: 13px;
  margin: 0 12px;
}

.detail_card {
  width: 100%;
  min-width: 400px;
  box-shadow: none;
  position: relative;
}

.detail_card_body {
  text-align: center;
}

.status {
  font-size: 12px;
  margin: 2px;
  padding: 3px 10px 3px 10px;
  border-radius: 20px;
  color: var(--app-white);
}

.status_text {
  margin-top: 0.1em;
  font-weight: 600;
}
.status_group {
  padding: 5px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.dropdown_toggle {
  position: absolute;
  right: 15px;
  color: var(--app-blue);
}

.exp_date_field {
  flex: 0.6 1;
}

.exp_date_label {
  display: flex;
}

.remarks_submit_button {
  padding: 7px 20px 7px 20px;
  background-color: var(--app-blue);
  color: var(--app-white);
  border-radius: 0.2em;
  font-size: 13px;
  margin-left: 25px;
}

.remarks_cancel_button {
  padding: 7px 20px 7px 20px;
  color: var(--app-blue);
  font-size: 13px;
}

.remarks_text {
  resize: none;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 14px;
}

.remarks_title {
  font-size: 18px;
  font-weight: bold;
}

.remarks_container {
  border-top: 1px solid var(--app-border-grey);
  padding: 15px 0px 10px 0px;
  margin-top: 15px;
}

.remarks_button_container {
  display: flex;
  justify-content: flex-end;
}