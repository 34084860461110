.modal_footer_container {
  display: flex;
  width: 100%;
  justify-content: end;
  padding-right: 20px;
}

.form_button {
  height: 36px;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;

  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.06px;
  text-align: center;
  border-radius: 2px;
}

.cancel_button {
  color: var(--text-primary);
  background: var(--app-offwhite);
}

.confirm_button {
  color: var(--app-white);
  background: var(--app-blue);
}
