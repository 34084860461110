.container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.left_container {
  flex: 1;
  border-right: 1px solid var(--app-border-grey);
}

.right_container {
  flex: 1;
}

.left_container,
.right_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.header {
  color: var(--text-primary);
  font-weight: bold;
  font-size: 20px;
}

.table_container {
  min-height: 500px;
  max-height: 600px;
  overflow-y: auto;
}

.list_row_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 10px 0px;
  gap: 5px;
}

.row_separator:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}

.list_column {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.list_column:nth-child(3),
.list_column:nth-child(4) {
  flex: 0.5 !important;
}

.list_column:nth-child(1) {
  justify-content: center;
}

.list_header_text {
  font-weight: bold;
  font-size: 14px;
}

.direction_button {
  padding: 0px 5px;
  color: var(--app-blue);
  cursor: pointer;
}

.remove_button {
  color: var(--status-red);
  cursor: pointer;
}

.direction_button:hover,
.remove_button:hover {
  opacity: 0.4;
}

.direction_button_disabled {
  opacity: 0.3;
  cursor: default;
}

.section_list {
  gap: 10px;
}

.checkbox_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0px;
}

.list_text_bold {
  font-weight: bold;
}

.list_text_semibold {
  font-weight: 500;
}
