.root {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 16px;
}

.ol {
  font-size: 18px;
  justify-content: center;
}

.breadcrumbs_item {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--app-blue);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
}

.breadcrumbs_item:hover {
  text-decoration: underline;
}

.breadcrumbs_item:disabled {
  cursor: none;
}

.breadcrumbs_item_last {
  color: var(--text-primary);
}

.icon_clickable {
  color: var(--app-blue);
  cursor: pointer;
}

.item_type_link {
  color: var(--app-blue);
  cursor: pointer;
  margin-left: 0.1em;
  margin-bottom: 0.05em;
}

.item_detail_container {
  font-size: 0.9em;
}

.item_container {
  flex-direction: row;
  display: flex;
}
