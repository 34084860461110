.location_tiles {
  margin-left: 20px;
  margin-right: 20px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  width: 33%;
  min-width: 400px;
  margin: 10px;
}
