.image_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
}

.image {
  cursor: pointer;
}

.header_container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 10px 10px 0 10px;
}

.enlarged_image {
  width: 100%;
  padding-bottom: 50px;
}

.close_icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.react_bootstrap_modal_style_override {
  transform: none !important;
  overflow-y: hidden !important;
}
