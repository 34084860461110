.right_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex-basis: 500px;
}

.overview_item_container {
  margin: 10px;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
  width: 220px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overview_title {
  font-size: 12px;
  color: var(--neutral-primary);
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 15px;
}

.overview_value {
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 600;
}
