.flex_row {
  display: flex;
  flex-direction: row;
}
.table_header {
  flex: 1;
  overflow-wrap: break-word;

  /* word-break: break-all; */
}

.table_column {
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
  font-size: 14px;
  margin-left: 15px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  margin-bottom: 1px;
  flex: 1;
}

.table_column_medium {
  flex: 2 !important;
}

.table_column_large {
  flex: 3 !important;
}

.table_item:nth-child(odd) {
  background-color: var(--app-offwhite);
}

.table_item_text {
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: anywhere;
  margin-right: 15px;
}
