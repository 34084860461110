.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.right_container {
  height: 100%;
}

.container {
  flex: 1;
}
.side_column {
  width: 250px;
  border-right: 2px solid var(--app-border-grey);
}
.side_column > div:not(:last-child) {
  border-bottom: 2px solid var(--app-border-grey);
}

.run_report_button_container {
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid var(--app-border-grey);
}

.run_report_button,
.run_report_button:hover {
  text-align: center;
  flex: 1;
  background-color: var(--app-blue);
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-weight: bold;
}

.run_report_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.content {
  position: relative;
  flex: 1;
  padding: 20px;
}
