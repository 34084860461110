.breadcrumb_container {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.breadcrumb_item {
  list-style-type: none;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--text-primary);
}

.active {
  color: var(--app-blue);
}

.active:hover {
  color: #3364b2;
  text-decoration: underline;
}
