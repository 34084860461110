.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.header_container {
  color: var(--text-primary);
  flex: 1;
  letter-spacing: 0.5px;
}

.header_close_button {
  width: 35x;
  height: 35px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: none;
  border: none;
  color: var(--app-blue);
}

.header_close_button:hover {
  opacity: 0.7;
}

.header_close_button_icon {
  font-size: 2rem !important;
}

.header_title_container {
  align-items: center;
  gap: 15px;
}

.header_title_icon_container {
  border: 4.5px solid var(--order-secondary);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_title_container > p {
  font-weight: bold;
  font-size: 1.2rem;
}

.footer_container {
  gap: 20px;
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.quantity_check_modal {
  display: relative;
  top: 25%;
  margin-left: 17%;
}

.quantity_check_modal_header {
  display: flex;
  flex-direction: column;
}

.quantity_check_modal_backdrop {
  opacity: 0 !important;
}

.quantity_check_modal_title {
  font-size: 0.7em;
  font-weight: 500;
  padding-left: 5px;
  font-weight: bold;
}

.quantity_check_modal_icon {
  margin: 0 auto;
  width: 4rem !important;
  height: 4rem !important;
  color: orange;
}

.footer_container > button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3px;
  padding: 8px 25px;
  letter-spacing: inherit;
}

.footer_container > button:disabled {
  opacity: 0.2;
}

.footer_container > button:focus {
  border: solid;
  border-color: var(--text-primary)
}

.footer_container > button:hover {
  opacity: 0.7;
}

.footer_container > button:first-child {
  background-color: #eeeeee;
}

.footer_container > button:last-child {
  color: white;
  background-color: var(--app-blue);
}

.modal {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: none !important;
  min-width: 700px;
  max-width: none;
  width: 64%;
  height: 100%;
  margin: 0;
}

.body_container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  letter-spacing: 0.5px;
  color: var(--text-primary);
}

.work_order_information {
  padding: 20px 40px;
  background-color: #fbfafa;
  row-gap: 20px;
}

.work_order_information > p {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}

.input_group_list {
  margin-top: 10px;
  grid-template-columns: repeat(5, 16%);
  column-gap: 5%;
  row-gap: 20px;
}

.attribute_label {
  font-weight: bold;
  font-size: 0.85rem;
}

.group_onboarding_container {
  width: 18%;
  padding: 0px 8px;
}

.label_asterisk {
  color: red;
  margin-left: 5px;
}

.bom_table_list {
  flex: 1;
  padding: 20px 40px;
  gap: 20px;
}

.group_onboarding_section {
  flex: 1;
}

.group_onboarding_rows {
  flex: 1;
  margin: 20px 40px;
  gap: 20px;
}

.group_onboarding_count {
  width: "8%";
  align-items: center;
}

.group_onboarding_row_container {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid var(--app-border-grey);
}

.group_onboarding_headers {
  border-bottom: 1px solid var(--app-border-grey);
}

.additional_feature_container {
  margin-left: 40px;
  padding: 20px 0px;
  width: 40%;
}

.additional_feature_container > p {
  font-weight: bold;
  font-size: 0.85rem;
}

.remark_container {
  width: 40%;
}

.remark_container > p {
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (max-width: 2400px) {
  .input_group_list {
    grid-template-columns: repeat(4, 19%);
    column-gap: 8%;
  }
}

@media screen and (max-width: 2100px) {
  .input_group_list {
    grid-template-columns: repeat(3, 27%);
    column-gap: 9.5%;
  }
}
