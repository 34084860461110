.last_updated {
  width: 170px;
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-primary);
}

.header {
  height: 65px;
  align-items: center;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: var(--app-white);
  border-top: 1px solid var(--app-border-grey);
  padding: 0px 10px;
}

.empty_items_container {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--app-border-grey);
}

.main_content {
  width: 100%;
  padding: 0px 60px 30px 60px;
}

.main_content_info_and_actions {
  display: flex;
  align-items: center;
  height: 75px;
}

.dropdown_section {
  display: flex;
  width: 80%;
}

.dropdown_title {
  font-family: "Avenir", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: var(--text-primary);
  align-self: center;
  margin-right: 7px;
}

.dropdown {
  width: 200px;
  height: 33px;
  padding: 1px 5px;
}

.dropdown_icon {
  background-color: var(--app-white);
  right: 1.4px;
  height: 90%;
  top: 0.5px;
  border-radius: 35%;
}

.status_section {
  display: flex;
  align-items: center;
  width: 100%;
}

.status_section_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status_text {
  width: 50%;
  text-align: left;
  font-family: "Avenir", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  color: var(--status-orange);
}

.status_count_section {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status_count_label {
  font-family: "Avenir", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: var(--text-primary);
}

.status_count_value {
  font-family: "Avenir", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: var(--inventory-primary);
  margin-left: 9px;
}

.filter_bar_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_bar_icon {
  position: absolute;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--neutral-primary);
  padding-bottom: 3px;
}

.filter_bar {
  height: 40px;
  border-radius: 3.8px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 1rem;
  color: var(--neutral-primary);
  padding-left: 2em;
  text-overflow: ellipsis;
  /* Text */
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-secondary);
}

.table {
  height: calc(100vh - 350px);
  width: 100%;
  border-radius: 3.8px;
  margin-top: 10px;
  overflow-y: auto;
}

.row_container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 20px 10px;
  align-items: flex-start;
}

.section_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section_content_container {
  flex: 1;
}

.row_image {
  border: 0.5px solid var(--app-border-grey);
  border-radius: 1px;
  box-shadow: 0px 4px 4px var(--app-border-grey);
}

.section_text_container {
  display: flex;
  flex-direction: row;
}

.section_label {
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 0.25em;
}

.verify_button {
  margin-left: 20px;
  height: 33px;
  width: 87px;
  background-color: var(--app-blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.84px;
  /* text */
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--app-white);
}

.clear_button {
  height: 33px;
  width: 87px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--app-blue);
  border-radius: 2.84px;
  /* text */
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--app-blue);
}
