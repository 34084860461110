.card_container {
  display: flex;
  width: 100%;
  flex: 1;
}

.create_button {
  font-weight: 400;
  text-align: center;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: var(--app-white);
  font-size: 0.9em;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--app-blue);
  display: block;
}

.side_bar_style {
  min-width: 190px;
  padding: 15px;
}

.buttons_group {
  padding: 15px;
}

.create_button:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  color: var(--app-white);
}

.seperator {
  margin-top: 15px;
  border-bottom: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.seperator_vertical {
  height: auto;
  margin: 0px;
}

.bottom_component_style {
  height: 100%;
  min-height: 600px;
  width: 100%;
  display: flex;
}

.data_view_container {
  width: 100%;
  padding: 0px 15px 15px 15px;
}

.data_view_group {
  width: 100%;
  margin-top: 15px;
}

.modal_body_heading {
  font-size: 1.1em;
}

.checkbox_style {
  margin-right: 0.5em;
}

.checkbox_container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.set_location_container {
  box-sizing: border-box;
  border: 1px solid var(--app-border-grey);
  background-color: var(--app-white);
  border-radius: 4px;
  box-shadow: 0 2px 9px -4px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 174px;
  flex-direction: column;
  width: 360px;
  padding: 18px;
}

.set_location_title {
  font-weight: 600;
  margin-top: 3px;
}

.set_location_dropdown {
  margin-top: 9px;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
  height: 30px;
}

.set_location_buttons {
  display: flex;
  float: right;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel_button {
  padding: 3%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--app-blue);
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
}

.modal_footer_container {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

.red_text {
  color: var(--status-red);
}

.skeleton_cols {
  margin-left: 15px;
  margin-right: 15px;
}

.skeleton_row {
  height: 73px;
  border-style: solid;
  border-color: var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right: none;
  border-left: none;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}