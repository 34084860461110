.modal_container {
  min-width: 68%;
  max-width: 960px !important;
}

.title_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: var(--asset-primary);
  border-color: var(--asset-secondary);
  stroke-width: 5;
}

.header_title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.modal_body_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-width: 720px
}

.popup_modal_div {
  margin-bottom: 6px;
  width: 100%;
}

.tab_bar {
  border-bottom: 1px solid var(--app-border-grey);
}

.tab_container {
  display: flex;
  flex-direction: column;
}
