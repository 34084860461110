.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.layout_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.layout_buttons_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.action_button {
  border: 2px solid var(--app-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--app-blue);
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--app-white);
}

.action_button:hover,
.action_button:active {
  opacity: 0.6;
}
