.location_button_container {
  height: fit-content;
  display: flex;
  justify-content: center;
}

.location_button {
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 7px 20px;
  font-size: 13px;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.backdrop {
  opacity: 0.5 !important;
}

.location_dropdown {
  width: 100%;
  height: 30%;
}

.cancel_button {
  background-color: #eaeaea;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: #343434;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.cancel_button:active,
.cancel_button:hover {
  filter: brightness(75%);
}

.save_button {
  background-color: #166ed5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: white;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.save_button:active,
.save_button:hover {
  filter: brightness(150%);
}