.summary_tile {
  cursor: pointer;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;

  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;

  width: 25%;
  max-width: 12em;
  min-width: 215px;

  height: 98%;
  min-height: 140px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
}

.summary_tile:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.55) !important;
}

.filter_disabled {
  opacity: 0.3;
}

.summary_tile_body {
  padding: 0em;
}

.dept_indicator_image {
  width: 10px;
  height: 10px;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}

.summary_tile_title {
  height: 2em;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  font-size: 0.8em;
  padding: 0.4em;
}

.summary_tile_content {
  background-color: "white";
  height: 5em;
}

.summary_tile_left_column {
  padding: 0.2em;
  margin-top: 1.5em;
  height: 100%;

  width: 50%;

  text-align: center;
  border-right: 0.2em solid #e2e0e0;
  float: left;
}

.left_column_number {
  font-size: 2em;
  font-weight: 500;
}

.left_column_text {
  font-size: 0.8em;
}

.summary_tile_right_column {
  display: flex;
  flex-direction: column;
  padding: 0.3em;

  font-size: 0.8em;
  font-weight: 300;
  /* padding: 0em; */
  margin-top: 2em;
  height: 85%;
  width: 50%;

  justify-content: center;
  vertical-align: middle;
  text-align: center;
  float: left;
}

.right_column_content {
  vertical-align: middle;
}

.status_count {
  width: 25%;
  margin-right: 1%;
  margin-left: 0.4em;
  float: left;
  text-align: right;
  overflow: hidden;
}

.status_name {
  padding-left: 0.3em;
  float: left;
  text-align: left;
  width: 67%;
  overflow: hidden;
}

p {
  margin-bottom: 0;
}
