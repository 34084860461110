.header_title {
  max-width: 800px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}

.header_subtext {
  font-size: 16px;
  margin-top: 10px;
}

.save_button {
  background-color: var(--app-blue);
  color: white !important;
  font-weight: bold;
}

.container {
  position: relative;
}

.table_container_disabled {
  opacity: 0.4;
  pointer-events: none;
}
