.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

li {
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
  justify-content: flex-start !important;
}
