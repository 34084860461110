.container {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.option_list {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: var(--neutral-primary);
}

.group_container {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search_input {
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  flex: 1;
  width: 100%;
  height: 30px;
  font-size: 12px;
  color: var(--text-primary);
  margin: 0;
  padding-top: 3px;
}

.item_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}

.no_data {
  display: flex;
  flex: 1;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--neutral-primary);
}

.checkbox_container {
  border: 1px solid var(--app-border-grey);
  height: 16px;
  width: 16px;
  padding: 1px;
  border-radius: 3px;
  line-height: 1.5;
  font-size: 12px;
  margin-right: 10px;
}

.checkbox_filled {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  background-color: var(--app-blue);
}

.checkbox_title {
  flex: 9;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  overflow: auto;
}

.more {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: var(--app-blue);
}

.more:hover {
  text-decoration: underline;
}
