.header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.header_container_active {
  cursor: pointer;
}

.header_container_active:hover {
  opacity: 0.6;
}

.header_text {
  font-weight: bold;
}

.icon_button {
  color: var(--app-blue);
  padding: 0px 5px 5px 5px;
}
