.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_header {
  flex: 1;
  border-top: 2px solid var(--app-border-grey);
  border-bottom: 2px solid var(--app-border-grey);
}

.empty_list_container {
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
}

.empty_list_text {
  font-weight: 500;
  color: var(--text-secondary);
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: center;
  gap: 5px;
}

.table_header_text {
  font-weight: 500;
  font-size: 14px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
}

.table_item_text {
  letter-spacing: 0.5px;
  font-size: 14px;
}

.table_item_text_clickable {
  color: var(--app-blue);
  cursor: pointer;
}

.table_item_text_clickable:hover {
  text-decoration: underline;
}

.selected_table_item {
  background-color: var(--app-lightblue);
}
