.card {
  flex-grow: 1;
  display: flex;
  width: 100%;
}

.create_button {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.02rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: var(--app-white);
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  background-color: var(--app-blue);
  display: block;
  white-space: nowrap;
}

.create_button:hover {
  color: white;
  box-shadow: 0px 3px 9px 0px var(--neutral-primary);
}

.seperator {
  margin: 15px 5px 15px 5px;
  border: 1px solid var(--app-border-grey);
}

.seperator_vertical {
  height: auto;
  margin: 0px 15px 0px 15px;
}

.bottom_component_style {
  height: 100%;
  min-height: 600px;
  width: 100%;
  display: flex;
  padding: 5px;
}

.data_view_container {
  width: 100%;
}

.data_view_group {
  width: 100%;
}

.modal_body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal_body_heading {
  font-size: 1.1em;
}

.modal_body_filter {
  flex: none !important;
}

.modal_checkbox_container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  overflow-y: scroll;
  flex: 3;
  height: 10%;
}

.checkbox_style {
  margin-right: 0.5em;
}

.checkbox_container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.tab_list_style {
  padding-top: 15px;
}

.tab_style {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 12px;
  line-height: 18px;
  margin-top: 4%;
  display: flex;
  align-items: center !important;
  width: 100%;
}

.tab_style:focus {
  outline: none;
}

.tab_style:hover {
  background-color: var(--app-white);
  color: var(--text-primary);
}

.disabled_tab_style {
  background-color: var(--app-offwhite);
  color: var(--app-white);
  cursor: default;
  pointer-events: none;
}

.disabled_tab_style:hover {
  color: var(--app-white);
  background-color: var(--app-offwhite);
  cursor: default;
  pointer-events: none;
}

.focused_tab_style {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.tab_style.focused_tab_style {
  color: var(--app-white);
}

.tab_style.focused_tab_style:hover {
  color: var(--app-white);
}

.groupby_label {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.filter_bar_container {
  margin: 5px 0px 5px 0px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

.buttons_container {
  margin: 5px 0px 5px 0px;
  display: flex;
  flex-direction: row;
}

.filter_and_buttons_group {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
}

.filter_bar {
  height: 40px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 1rem;
  color: var(--neutral-primary);
  padding-left: 2em;
  margin-right: 10px;
  padding-right: 10px;
  text-overflow: ellipsis;
}

.filter_bar_icon {
  position: relative;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--neutral-primary);
  padding-bottom: 3px;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
}

.icon_button:hover {
  color: var(--app-white);
  background-color: var(--app-blue);
}

.grid_card_container_style {
  margin: 10px;
}

.main_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
}

.filter_container {
  padding: 15px;
  width: 200px;
}

.filter_title_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.hide_filter {
  font-size: 12px;
  font-weight: 400;
  color: var(--app-blue);
  cursor: pointer;
}

.hide_filter:hover {
  text-decoration: underline;
}

.page_info_container {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px;
}

.back_btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.back_btn_container :hover {
  text-decoration: underline;
}

.back_btn_icon {
  color: var(--app-blue);
  padding-bottom: 2px;
}

.back_btn_text {
  font-size: 14px;
  color: var(--app-blue);
}

.breadcrumb_container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_tab_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter_tab_label {
  font-size: 12px;
  color: var(--text-primary);
  padding-top: 5px;
  padding-right: 5px;
  font-weight: 500;
  margin: 5px;
}

.filter_tab_list {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.filter_tab_item_group {
  padding-left: 10px;
  padding-right: 5px;
  margin: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 1.8em;
}

.filter_tab_item_title {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
  padding-right: 15px;
}

.filter_tab_item_close_icon {
  cursor: pointer;
  width: 15px !important;
  margin-left: auto;
  font-size: 12px;
  color: var(--app-blue);
  font-weight: 500;
}

.filter_tab_more_count {
  font-size: 12px;
  margin: 5[x];
  color: var(--text-primary);
  font-weight: 500;
}

.filter_action_btn {
  cursor: pointer;
  font-size: 12px;
  margin: 5px;
  color: var(--app-blue);
  font-weight: 500;
}

.filter_action_btn:hover {
  text-decoration: underline;
}

.data_container {
  display: flex;
  flex: 1;
  padding: 15px;
  flex-direction: column;
}

.view_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.view_label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  padding-right: 20px;
}

.view_btn {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  background-color: var(--app-white);
  white-space: nowrap;
}

.csv_btn {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.02rem;
  padding: 0.48rem 0.8rem 0.48rem 0.8rem;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: var(--app-white);
  text-align: center;
  background-color: var(--app-blue);
  display: block;
  white-space: nowrap;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.csv_btn:hover {
  color: white;
  box-shadow: 0px 3px 9px 0px var(--neutral-primary);
}

.csv_icon {
  margin-right: 5px;
  padding-bottom: 3px;
}

.view_btn_active {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--app-white);
  background-color: var(--app-blue);
}

.small_action_container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.sort_header_btn {
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
  color: var(--app-blue);
}

.sort_header_btn:hover {
  text-decoration: underline;
}

.header_collapse_btn {
  cursor: pointer;
  font-size: 12px;
  color: var(--app-blue);
}

.header_collapse_btn:hover {
  text-decoration: underline;
}

.datalist_container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_container {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
}

.header_group {
  display: flex;
  align-items: center;
  padding-right: 20px;
  min-width: 10%;
}

.header_group_not_clickable {
  cursor: default;
}

.header_title {
  color: var(--app-blue);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.header_title_not_clickable {
  text-decoration: none;
}

.header_title_icon {
  margin-right: 10px;
  border-radius: 15px;
  background-color: var(--app-blue);
  width: 17px !important;
  height: 17px !important;
  cursor: pointer;
}

.header_data_group {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding-right: 25px;
  justify-content: space-between;
}

.header_data_label {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-primary);
  padding-right: 10px;
  white-space: nowrap;
}

.header_data_value {
  margin-top: 1px;
  font-size: 12px;
  color: var(--text-primary);
}

.header_show_more_btn {
  font-weight: 500;
  font-size: 12px;
  color: var(--app-blue);
  margin-left: auto;
  cursor: pointer;
}

.header_show_more_btn:hover {
  text-decoration: underline;
}

.table_container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.table_empty {
  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--text-secondary);
  font-weight: 500;
}

.no_data {
  display: flex;
  flex: 1;
  height: 250px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: var(--neutral-primary);
}

.hide_sort_icon {
  display: none;
}

.metrics_group {
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: left;
  left: 50%;
}

.sub_header_group {
  padding-left: 20px;
}

.navigation_group {
  flex: 1;
}

.left_main_container {
  border-right: 1px solid var(--app-border-grey);
}

.buttons_group {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px;
}

.content_container {
  margin-bottom: 0.8em;
}

.icon_offset {
  position: relative;
  margin-right: -24px;
}

.skeleton_cols {
  margin-left: 15px;
  margin-right: 15px;
}

.skeleton_row {
  height: 55px;
  border-style: solid;
  border-color: var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right: none;
  border-left: none;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.skeleton_header {
  margin-top: 10px;
}

.dot_flashing {
  position: relative;
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--app-offwhite);
  color: var(--app-offwhite);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot_flashing::before,
.dot_flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot_flashing::before {
  right: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--app-offwhite);
  color: var(--app-offwhite);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot_flashing::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--app-offwhite);
  color: var(--app-offwhite);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--app-offwhite);
  }
  50%,
  100% {
    background-color: var(--app-offwhite);
  }
}
