.modal_title {
  padding: 30px 0px 30px 30px;
  font-weight: 600;
  font-size: 25.82px;
  line-height: 35px;
  letter-spacing: 0.0660511px;
  color: var(--text-primary);
}

.modal_body {
  padding: 0px 30px 30px 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.0460511px;
  color: var(--text-primary);
  border-bottom: 2px solid var(--app-border-grey);
}

.modal_footer {
  padding: 20px 30px;
}

.cancel_button {
  height: 36px;
  width: 118px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--app-offwhite);
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15.12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.063px;
  color: var(--text-primary);
}

.confirm_button {
  margin-left: 20px;
  height: 36px;
  width: 118px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--app-blue);
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15.12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.063px;
  color: var(--app-white);
}
