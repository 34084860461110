.not_found_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

.not_found_main_text {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: var(--text-primary);
  padding-bottom: 20px;
}

.not_found_sub_text {
  text-align: center;
  padding-bottom: 20px;
}

.button {
  background-color: var(--app-blue);
  color: white;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}
