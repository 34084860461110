.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.information_header {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px 10px;
}

.information_title_container {
  padding: 5px 10px;
  align-items: center;
}

.information_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
}

.information_title_status {
  margin-left: 10px;
  padding: 0px 15px;
  border-radius: 20px;
  background-color: var(--status-yellow);
  color: white;
  font-weight: bold;
}

.search_results_close_button {
  margin-left: auto;
  cursor: pointer;
  color: var(--app-blue);
  padding: 3px;
  border-radius: 100%;
}

.search_results_close_button:hover {
  background-color: var(--app-offwhite);
}

.location_result_group {
  padding: 20px;
  border-bottom: 1px solid var(--app-border-grey);
  gap: 15px;
}

.location_result_group:hover {
  background-color: var(--app-offwhite);
}

.location_point_icon {
  justify-content: center;
  align-items: center;
  width: 25px;
  color: var(--app-blue);
}

.location_result_label {
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
}

.location_work_order_group {
  padding-left: 25px;
}

.location_work_order {
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.location_work_order_label {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.location_work_order_status {
  align-self: flex-end;
  justify-self: flex-end;
  gap: 10px;
  margin-left: 30px;
  flex-wrap: wrap;
}

.information_title_status {
  margin-left: 10px;
  padding: 0px 15px;
  border-radius: 20px;
  background-color: var(--status-yellow);
  color: white;
  font-weight: bold;
}

.link {
  color: var(--app-blue);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
