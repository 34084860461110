.uploaded_file_container {
  flex: 1;
  flex-direction: column;
}

.upload_process_container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.progress_bar {
  height: 10px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  border-radius: 10px;
}

.progress_bar_container {
  width: 100%;
  padding: 0px 10px;
}

.upload_info_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.upload_file_text_container {
  display: flex;
  flex-direction: row;
}

.filename {
  font-weight: bold;
}

.remove_button {
  color: var(--app-blue);
  cursor: pointer;
  font-weight: bold;
}
