.grid_card_container_style {
  width: 500px;
  height: 350px;
  margin: auto;
  padding: 10px;
  border-top: 10px solid white;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  color: black;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.title_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  display: inline-flex;
  margin-left: 37%;
}

.name_container {
  justify-content: center;
  display: flex;
}

.dropdown_toggle {
  padding: 0px 0px 0px 65px;
}

.btn {
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 7px 20px 7px 20px;
  font-size: 13px;
}

.blue_btn {
  color: white;
  background: var(--app-blue);
}

.gray_btn {
  background: whitesmoke;
  color: gray;
}

.button_container {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin: auto auto 20px;
}

.image {
  width: 50%;
  justify-self: center;
  align-self: center;
}

.identifier {
  justify-content: center;
  display: flex;
  margin-top: 2%;
}

.form_input {
  height: 25px;
  width: 300px;
  font-size: 12px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: white;
  overflow: hidden;
}

.alert_button {
  cursor: pointer;
  color: var(--app-blue);
  background-color: white;
}

.red_text {
  color: #f62227;
}
