.request-header {
  border-bottom: 1px solid gray;
}

.input-error-form {
  border: 1px solid red;
  border-radius: 0.3rem;
}

.back-button-text {
  width: fit-content;
  cursor: pointer;
  color: #1e71d2;
}

.back-button-icon {
  width: 10px;
  vertical-align: baseline;
}

.search-form {
  margin: 0em 5em 3em 5em;
}

.search-form-label {
  font-weight: bold;
  font-size: 1.5em;
}

.new-request-card {
  min-height: 50em;
  margin: 0px 0px 20px 20px;
}

.submit-form {
  column-count: 2;
  margin: 2em 10em 2em 10em;
}

.horizontal-center-align {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.form-input-label {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 0.2em;
}

.input-div {
  margin-bottom: 2em;
  background: transparent;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.form-control[readonly].form-input-field-readonly {
  background-color: transparent;
  border: none;
  font-size: 1.3em;
  width: 100%;
}

.form-input-field-editable-s {
  width: 20%;
}

.form-input-field-editable-m {
  width: 50%;
}

.input-error-message {
  color: red;
}

.text-area-input {
  resize: none;
  display: flex;
  background: transparent;
  height: 100px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.text-area-input:focus {
  color: #495057;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
