.modal-content {
  flex: 1;
}

.run_report_modal-header {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 !important;
  border-bottom: 1px solid var(--app-border-grey) !important;
}

.run_report_modal-title {
  flex: 1;
}
.run_report_modal-body {
  padding: 0px;
  display: flex;
}
.run_report_modal-footer {
  padding-left: 30px;
  padding-right: 30px;
}
