.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.map_container {
  width: 100%;
  height: 100%;
  max-height: 100%;

  position: relative;
}

.map_tool_container {
  border: 1px solid var(--app-border-grey);
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.map_control {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 1;
  overflow: hidden;
  right: 0px;
  top: 0px;
  transition: 500ms ease-in-out;
}

.map_control_button {
  color: var(--app-blue);
  width: 35px;
  height: 33px;
}

.map_control_button:not(:last-child) {
  border-right: 1px solid var(--app-border-grey);
}

.map_control_button:hover {
  background-color: #f7f7f7;
}

.color_indicator_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 5px 10px;
  z-index: 1;
  overflow: hidden;
  right: 0px;
  bottom: 0px;
  transition: 500ms ease-in-out;
}

.color_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color_icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
}

.color_label {
  font-weight: 500;
}
