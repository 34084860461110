.header_container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 2px 5px 0 var(--app-border-grey);
}

.header_row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  background-color: var(--app-blue);
  color: var(--app-white);
  padding: 5px;
  font-size: 0.9em;
  border-radius: 3px 3px 0px 0px;
}

.rows_container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding-left: 8px;
}

.row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  color: var(--text-primary);
  border-bottom: 1pt solid var(--app-border-grey);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-bottom: 0.3em;
  padding-left: 0.2em;
}

.empty_row {
  color: var(--text-primary);
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.row_text {
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 400;
  font-size: 0.9em;
}

.secondary_row_text {
  font-size: 0.9em;
  text-align: left;
  align-self: center;
  font-weight: 600;
}

.secondary_header_text {
  font-size: 0.9em;
  text-align: left;
  align-self: center;
  font-weight: 600;
  min-width: 4em;
  margin-left: 0.25em;
}

.comments {
  font-size: 0.9em;
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 800;
  flex: 1;
}

.header_text {
  text-align: left;
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 600;
  margin-left: 0.5em;
}

.secondary_header_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  color: var(--text-primary);
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  height: 2em;
  background-color: var(--app-white);
  border-left: 0.2em solid var(--app-border-grey);
  margin-left: 0.25em;
}
.secondary_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  color: var(--text-primary);
  padding: 5px;
  font-size: 0.9em;
  border-left: 0.4em solid var(--status-red);
  margin-left: 0.3em;
  margin-bottom: 0.3em;
}

.status {
  color: "white" !important;
  width: "fit-content" !important;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 1em;
  margin-bottom: 0.2em;
}

.underline_text {
  text-decoration: underline;
}
