.dropdown_container {
  position: relative;
}

.change_data_view_button {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px 10px;
  color: var(--app-blue);
}

.change_data_view_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  color: var(--app-white);
  background: var(--app-blue);
}

.change_data_view_button_active {
  color: var(--app-white);
  background: var(--app-blue);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
}

.change_data_view_icon {
  color: inherit;
  height: 30px;
  width: 30px;
}

.change_data_view_icon:hover {
  color: var(--app-white);
}

.change_data_view_text {
  font-weight: 500;
}

.change_data_view_button:hover > .change_data_view_text {
  color: var(--app-white);
}

.data_view_dropdown_background {
  z-index: 2;
  background-color: var(--app-white);
  border: 1px solid var(--app-border-grey);
  padding: 10px 0px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  transform: translate(0px, 10px);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 10px;
}

.data_view_dropdown_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  color: var(--text-primary);
  cursor: pointer;
  font-weight: 500;
}

.data_view_dropdown_item:hover {
  background-color: var(--app-offwhite);
}
