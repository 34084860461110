.dot_flashing {
  position: relative;
  left: 8px;
  width: 4px;
  height: 4px;
  margin-top: 6px;
  border-radius: 2px;
  background-color: #bababd;
  color: #bababd;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot_flashing::before,
.dot_flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot_flashing::before {
  right: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #bababd;
  color: #bababd;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot_flashing::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #bababd;
  color: #bababd;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #bababd;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
