.search_container {
  position: relative;
  display: flex;
  align-items: center;
}

.search_icon {
  position: absolute;
  left: 5px;
  color: var(--text-secondary);
  padding-bottom: 5px;
  width: 20px;
  text-align: center;
}

.search_input {
  width: 100%;
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  padding: 6px 30px;
  font-weight: 500;
  font-size: 14px;
}

.search_input::placeholder {
  color: var(--text-secondary);
}
