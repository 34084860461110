.upload_status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  color: var(--status-green);
}

.status_icon {
  position: relative;
  bottom: 2px;
}
