.icon_button {
  min-width: 40px;
  min-height: 40px;
  font-size: 24px;
  padding: 4px;
}
.dropdown_toggle {
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 4px;
  background-color: transparent !important;
  color: black;
  margin: 0px;
  margin-left: 15px !important;
  margin-right: 10px !important;
  box-shadow: none;
}

.dropdown_menu {
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 9px -2px;
  border: 0;
}

.dropdown_toggle:hover,
.dropdown_toggle:focus {
  background-color: var(--app-blue) !important;
  color: white !important;
  box-shadow: none !important;
}

.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--app-blue) !important;
  color: white !important;
  box-shadow: none !important;
}

.dropdown_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_item:hover,
.dropdown_item:focus,
.dropdown_item.active {
  background-color: var(--app-blue) !important;
  color: white !important;
}

.title {
  margin-right: 15px;
}
