.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.group {
  gap: 10px;
}

.container_background {
  flex: 1;
  gap: 10px;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 3px;
  padding: 7px 15px;
  background-color: var(--inventory-secondary);
}

.container {
  flex: 1;
  align-items: baseline;
  gap: 30px;
}

.header {
  align-items: baseline;
  gap: 10px;
}

.expand_collapse_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--app-blue);
  color: white;
  cursor: pointer;
  padding: 0px;
}

.header_text,
.header_text:hover {
  font-weight: bold;
  text-decoration: underline;
  color: var(--app-blue);
}

.metric {
  gap: 5px;
  font-size: 14px;
  align-items: baseline;
}

.metric_header {
  font-weight: 500;
}

.expand_collapse_btn {
  justify-self: flex-end;
  padding: 0px;
  color: var(--app-blue);
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
}

.list {
  margin-left: 20px;
  gap: 10px;
}

.hide_list {
  display: none;
}
