.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.metrics {
  gap: 40px;
  flex: 1;
}
