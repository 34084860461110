.form_section_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
}

.input_text_container {
  display: flex;
  flex-direction: row;
}

.required_asterisk {
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--status-red);
  margin-left: 3px;
}

.input_title {
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--text-primary);
}

.readonly_input {
  background-color: transparent;
  border-color: transparent;
}
