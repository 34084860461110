.container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: var(--neutral-primary);
}

.group_container {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search_icon {
  position: absolute;
  font-size: 0.9rem;
  color: var(--neutral-primary);
  margin-left: 5px;
  align-self: center;
  padding-bottom: 2px;
}

.search_input {
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  font-size: 12px;
  color: var(--text-primary);
  padding: 4px 5px 2px 25px;
}

.item_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}

.checkbox_container {
  border: 1px solid var(--app-border-grey);
  height: 16px;
  width: 16px;
  padding: 1px;
  border-radius: 3px;
  line-height: 1.5;
  font-size: 12px;
  margin-right: 10px;
}

.checkbox_filled {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  background-color: var(--app-blue);
}

.checkbox_title {
  flex: 9;
  font-size: 12px;
  line-height: 1.5;
  overflow: auto;
}

.more {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: var(--app-blue);
}

.more:hover {
  text-decoration: underline;
}
