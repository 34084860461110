.container {
  padding: 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  min-height: 500px;
}

.list_container {
  max-height: 800px;
  overflow-y: auto;
}

.header_text {
  font-weight: bold;
}

.header_subtext {
  font-size: 16px;
  margin-top: 10px;
}

.table_header {
  border-bottom: 1px solid var(--app-border-grey);
  flex: 1;
  gap: 10px;
}

.add_button {
  color: var(--text-primary);
  letter-spacing: 0.5px;
  color: var(--app-blue);
  cursor: pointer;
  font-weight: 500;
}

.add_button:hover {
  text-decoration: underline;
  opacity: 0.4;
}

.edit_button {
  padding: 0px 5px;
  cursor: pointer;
  color: var(--app-blue);
}

.edit_button:hover {
  text-decoration: underline;
  opacity: 0.4;
}

.header_row_container:nth-child(2),
.header_row_container:nth-child(3) {
  flex: 0.3;
  justify-content: center;
}

.list_empty_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
}
