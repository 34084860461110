.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text {
  color: var(--app-lightgrey);
}
