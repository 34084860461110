.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.recent_items_container {
  justify-content: center;
  width: 33%;
  min-width: 375px;
}

.horizontal_line {
  width: 25%;
  border-width: 0.3em;
  margin-bottom: 2em;
  border-bottom-style: solid;
  margin-top: auto;
  border-color: #d8d8d8;
}

.recent_item_count_display_container {
  padding: 0.5em;
  margin-bottom: 0.5em;
  width: 45%;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2em;
}

.recent_items_label {
  margin-right: 0.6em;
  padding-top: 1px;
  overflow: hidden;
  text-align: center;
  color: var(--text-primary);
}

.recent_item_count_value {
  background-color: #319aff;
  color: white;
  padding: 0.1em 1.4em 0.15em 1.4em;
  border-radius: 11px;
  overflow: hidden;
  text-align: center;
}
