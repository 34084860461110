.footer_container {
  display: flex;
  justify-content: flex-end;
}

.button_container {
  display: flex;
}

.button:disabled,
button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.cancel_button {
  height: 36px;
  width: 118px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--app-offwhite);
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15.12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.063px;
  color: var(--text-primary);
}

.confirm_button {
  margin-left: 20px;
  height: 36px;
  width: 118px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--app-blue);
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15.12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.063px;
  color: #ffffff;
}
