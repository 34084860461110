.content_holder {
  height: auto;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detail_group {
  flex: 0.25;
  border-right: 1px solid var(--app-border-grey);
}

.detail_element {
  margin-top: 30px;
}

.table_group {
  flex: 0.75;
  padding: 0px 0px 10px 10px;
}

.title_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 5px 5px 5px;
  padding: 5px;
  line-height: 30px;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  color: var(--text-primary);
}

.top_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
  min-width: 770px;
}

.search_input {
  width: 100%;
  padding: 6px 0px;
  font-weight: 500;
  font-size: 14px;
}

.table_header {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--app-border-grey);
  border-radius: 3px 3px 0px 0px;
  background-color: var(--app-blue);
}

.table_column {
  display: flex;
  flex: 1;
  padding: 0px 0px;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
  min-width: 120px;
}

.table_column_container {
  display: flex;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
  cursor: pointer;
}

.table_column:first-child {
  padding-left: 20px;
}

.table_header_text {
  align-self: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--app-white);
}

.table_items {
  flex: 1;
  display: flex;
  flex-direction: flex-start;
}

.table_item:first-child {
  padding-left: 20px;
}

.table_item {
  flex: 1;
  display: flex;
  word-break: break-all;
  align-content: center;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  min-width: 120px;
}

.table_item_text {
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.table_row {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px 0px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  grid-gap: 20px;
  border-bottom: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
  border-right: 1px solid var(--app-border-grey);
}

.flex_container {
  display: flex;
  flex-direction: row;
}

.status_text {
  color: var(--app-white);
  font-size: 14px;
  font-weight: bold;
  flex: 1;
  margin: 5px;
}

.status_success {
  color: var(--app-white);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--status-green);
}

.status_partial {
  color: var(--app-white);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--status-orange);
}

.status_incomplete {
  color: var(--app-white);
  width: 85px;
  height: 22px;
  border-radius: 10px;
  background-color: var(--status-red);
}

.direction_button {
  width: 10px;
  height: 6px;
  opacity: 0.3;
  color: var(--app-white);
  cursor: pointer;
}

.direction_button_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  align-self: center;
  background-color: var(--app-blue);
}

.direction_button:hover,
.remove_button:hover {
  opacity: 0.4;
}

.direction_button_selected {
  opacity: 1;
}

.direction_button_disabled {
  opacity: 0.3;
  cursor: default;
}

.empty_list_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  border: 1px solid var(--app-border-grey);
  border-top: none;
}

.empty_list_text {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-secondary);
  margin: 20px 0px 20px 0px;
}

.export_button,
.export_button:hover {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--app-white);
  border-radius: 3px;
  border: 1px solid var(--app-blue);
  color: var(--app-blue);
  padding: 5px;
  height: 35px;
  margin: 6px 0px 5px 10px;
}

.export_button:hover {
  background-color: var(--xemelgo-light-blue);
}

.export_button:active {
  opacity: 0.9;
}

.export_icon {
  flex: 1;
  color: var(--app-blue);
  margin-left: 14px;
}

.export_text {
  flex: 3;
  font-size: 14px;
  font-weight: bold;
  color: var(--app-blue);
  margin: 12px 14px 10px 6px;
}
