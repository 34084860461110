@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.content-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.content-body.login-page-body {
  background-image: url("../../img/background.png");
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.login-form {
  text-align: center;
  max-width: 460px;
  flex: 0.6;
}

.form-group {
  padding-bottom: 0.3em;
}

.form-group-email-only {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.3em;
  gap: 20px;
}

.md-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.login-form-background {
  background-color: rgba(25, 25, 25, 0.9);
  padding: 3em;
  border-radius: 0.5em;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.9);
}
.login-form > section {
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
}

.login-page-body .md-form {
  font-weight: normal;
}

.login-form a {
  color: #fff;
}

.login-form img {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.form-control + .glyphicon {
  position: absolute;
  left: 0;
  top: 14%;
  padding: 8px 0 0 17px;
  font-size: 15px;
  color: #757575;
}

.login-form label {
  color: var(--app-white);
}

.input-default {
  text-align: center;
  color: white;
  background-color: transparent;
}

.input-default:focus {
  color: white;
  background-color: transparent;
}

.btn-block {
  padding: 2vh;
}

.brand-logo {
  height: 15rem;
}

.center-align {
  text-align: center;
}

.asText {
  background: none;
  border: none;
  margin-top: 15px;
  padding: 0;
  color: white;
  cursor: pointer;
}

.error_message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--status-red);
  font-size: 16px;
  text-align: center;
}

.profile-form-buttons {
  display: flex;
  justify-content: center;
}

.next_button {
  cursor: pointer;
  color: white;
  height: 30px;
  width: 30px;
  margin-top: 25px;
}

.next_button:hover {
  color: #166ed5;
}
