.container {
  display: flex;
  flex-direction: column;

  gap: 10px;
  flex: 1;
}

.title {
  font-weight: bold;
}

.image_container {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
