.menu-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.menu-button:hover {
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.8em;
  background-color: blue;
}

.vertical-tag {
  flex-direction: column;
  display: flex;
}

.tab-style,
.tab-style-selected {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.01rem;
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: Black;
  font-size: 0.8em;
  margin: 5px 0 5px 0;
}

.tab-style-no-requests {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  font-style: italic;
  letter-spacing: 0.01rem;
  padding-bottom: 0.25rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  color: Black;
  font-size: 0.8em;
  margin: 1px;
}

.tab-style-selected {
  background-color: #1e71d2;
  color: white;
}

.tab-style:hover {
  background-color: #1e71d2;
  color: white;
}

#searchKeyword {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 35px;
  font-size: 0.7rem;
  border-radius: 0.25rem;
}
