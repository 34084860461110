.single_create_form {
  padding-left: 32px;
  background-color: #FBFAFA;
}

.form_title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.form_container {
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
  padding-right: 20px;
}

.first_section_container {
  width: 235px;
  margin-right: 40px;
  column-gap: 36px;
  row-gap: 30px;
}

.second_section_container {
  column-gap: 36px;
  row-gap: 30px;
}

.printer_component {
  padding-left: 32px;
}

.modal_disabled {
  opacity: 60%;
  pointer-events: none;
}

.section_seperator {
  width: 1.5px;
  background-color: var(--app-border-grey);
  margin-right: 40px;
  flex-shrink: 0;
}

.field_container {
  min-height: 63.5px;
  width: 235px;
}
