html {
  min-height: 100%;
}

#root {
  height: 100%;
}

body {
  background-color: #fafafa;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Avenir", sans-serif;
}

pre {
  font-family: "Avenir", sans-serif;
}

main {
  display: block;
}

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 8px;
  padding-bottom: 8px;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, 0.35);
  float: right;
}

.cancel-button,
.confirm-delete-button,
.default-button,
.switch-button,
.add-user-button,
.manual-route-button,
.request-submit-button {
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.cancel-button:active,
.confirm-delete-button:active,
.default-button:active,
.switch-button:active,
.add-user-button:active,
.manual-route-button:active,
.request-submit-button:active {
  filter: brightness(75%);
}

.cancel-button:disabled,
.confirm-delete-button:disabled,
.default-button:disabled,
.switch-button:disabled,
.add-user-button:disabled,
.manual-route-button:disabled,
.request-submit-button:disabled {
  cursor: default;
  opacity: 0.3;
}

.cancel-button {
  background-color: #eaeaea;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: var(--text-primary);
  margin-right: 4%;
  width: 10em;
}

.confirm-delete-button {
  background-color: #f62227;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #f62227;
  color: white;
  width: 10em;
}

.default-button {
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-blue);
  color: white;
  display: inline-block;
  width: 10em;
}

.switch-button {
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #46d2ff;
  color: white;
  height: 20%;
  margin-right: 4%;
  width: 40%;
}

.request-submit-button {
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-blue);
  color: white;
  display: inline-block;
  width: 13em;
  font-size: 1.2em;
}

.add-user-button {
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-blue);
  color: white;
  width: 100%;
}

.manual-route-button {
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-blue);
  color: white;
  padding: 7px;
  margin-bottom: 0.6em;
}

.onboard-submit-button {
  text-transform: none;
  letter-spacing: 0.04rem;
  margin-left: 0.5em;
  margin-right: 1em;
}

.delete-button-small {
  border-radius: 3px;
  padding: 1em 0.7em;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f62227;
  color: var(--app-white);
  cursor: pointer;
  font-weight: 600;
}

.submit-button-small {
  border-radius: 3px;
  padding: 1em 0.7em;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-blue);
  color: var(--app-white);
  cursor: pointer;
  font-weight: 600;
}

.cancel-button-small {
  border-radius: 3px;
  padding: 1em 0.7em;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--app-blue);
  cursor: pointer;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .cancel-button,
  .confirm-delete-button,
  .default-button,
  .switch-button {
    font-size: 1em;
  }

  .manual-route-btn {
    display: inline-block;
    padding: 7px;
    margin-bottom: 0;
  }

  .switch-button {
    width: 20%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-chatbot-kit-chat-container {
  height: 88vh !important;
  position: relative !important;
  right: 0;
  width: auto !important;
}

.react-chatbot-kit-chat-inner-container {
  height: 100% !important;
  flex: 1 !important;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px !important;
}

.react-chatbot-kit-chat-message-container {
  background-color: #f7fafe !important;
  flex: 0.89;
  border-bottom: 1px solid var(--app-border-grey);
}

.react-chatbot-kit-chat-message-container > .react-chatbot-kit-chat-bot-message-container:first-child {
  display: none;
}

.react-chatbot-kit-chat-bot-message,
.react-chatbot-kit-user-chat-message {
  font-size: var(--font-size-small) !important;
  /* font-family: var(--font-semibold) !important; */
}

/* Chatbot Message */
.react-chatbot-kit-chat-bot-message {
  width: auto !important;
  margin-left: inherit !important;
  color: var(--text-primary) !important;
}

/* User Message */
.react-chatbot-kit-user-chat-message {
  background-color: var(--xemelgo-blue) !important;
  color: #ffffff !important;
}

.react-chatbot-kit-user-chat-message-arrow {
  border-left: 8px solid var(--xemelgo-blue) !important;
}

.chatbot-loader-container {
}

.react-chatbot-kit-chat-header {
  justify-content: space-between;
  padding: 20px 14px 20px 14px !important;
  flex: 0.05 !important;
  background-color: #ffffff !important;
  font-size: var(--font-size-base) !important;
  /* font-family: var(--font-bold) !important; */
  font-weight: bold !important;
  color: var(--text-primary) !important;
  border-top-left-radius: 10px !important;
  border-bottom: 1px solid var(--app-border-grey);
  border-top: 1px solid var(--app-border-grey);
}

.react-chatbot-kit-chat-input-container {
  background-color: white;
}

.react-chatbot-kit-chat-input-form {
}

.react-chatbot-kit-chat-input {
  border-top: 1px solid var(--app-border-grey) !important;
  border-bottom: 1px solid var(--app-border-grey) !important;
  border-radius: 0px !important;
  font-size: var(--font-size-small) !important;
  /* font-family: var(--font-regular) !important; */
  color: var(--text-primary) !important;
}

.react-chatbot-kit-chat-input::placeholder {
  color: var(--text-secondary) !important;
}

.react-chatbot-kit-chat-btn-send {
  border-radius: 0px !important;
}

.react-chatbot-kit-chat-btn-send-icon {
}

.react-chatbot-kit-chat-bot-message-container {
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
}

.react-chatbot-kit-chat-bot-message-arrow {
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

#chatbot-loader #chatbot-loader-dot1 {
  fill: var(--text-secondary);
}

#chatbot-loader #chatbot-loader-dot2 {
  fill: var(--text-secondary);
}

#chatbot-loader #chatbot-loader-dot3 {
  fill: var(--text-secondary);
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}

.fc-widget-small {
  right: 35px !important;
}