.show_password_toggle_icon {
  color: var(--app-white);
}

.show_password_toggle_icon_container {
  width: 5em;
  color: var(--app-white);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.show_password_icon_label {
  padding-top: 0.1em;
  margin-left: 0.6em;
}
