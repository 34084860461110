.alert_card {
  border: 1px solid #6cb444;
  background-color: #f0fef0;
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 15px 20px;
}

h5 {
  font-weight: 500;
}

.bold_text {
  font-weight: bold;
}

.toggle {
  padding: 0px !important;
  color: var(--app-blue);
}

.red_text,
.red_text:hover {
  color: red;
}

.confirm_delete_button {
  background-color: #f62227;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #f62227;
  color: white;
  width: 10em;
}

.confirm_delete_button_hidden {
  display: none;
}

.delete_prompt_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
