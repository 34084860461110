.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.side_filter_container {
  gap: 10px;
}

.title_container {
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
}

.hide_filter_button {
  color: var(--app-blue);
}

.hide_filter_button:hover {
  text-decoration: underline;
}

.filter_group {
  gap: 5px;
  margin-bottom: 15px;
}

.filter_group_title {
  font-weight: 500;
  color: var(--text-secondary);
}

.filter_group_filter {
  gap: 10px;
  flex-wrap: wrap;
}

.filter_title {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
}
