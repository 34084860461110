.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.title {
  font-weight: bold;
}

.metrics_group_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  overflow-y: auto;
  min-height: 150px;
}

.metrics_container {
  width: calc(20% - 20px);
  border: 2px solid var(--app-border-grey);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  gap: 20px;
}

.metrics_container_active {
  background-color: var(--app-lightblue);
}

.metrics_container_active,
.metrics_container:hover {
  border-color: var(--app-blue);
}

.metrics_label {
  font-weight: bold;
  font-size: 14px;
}

.metrics_subTitle_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.metrics_subTitle {
  font-weight: bold;
  color: var(--app-blue);
  font-size: 22px;
}

.metrics_inner_subTitle {
  font-size: 17px;
  margin-left: 8px;
}

.metrics_percentage {
  border-radius: 25px;
  width: 100px;
  border: 1px solid var(--app-border-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--app-border-grey);
}

.popover_content_container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
}

.popover_content_icon {
  color: var(--app-blue);
}

.popover_content_text {
  max-width: 15vw;
  text-wrap: wrap;
}
