ul {
  margin: 0;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.report_class_filter_container {
  margin: 20px 0px;
  gap: 20px;
}

.report_class_filter_container > li {
  padding: 10px 0px;
  padding-right: 4px;
}

.report_class_filter_container > li::before {
  content: "";
  position: relative;
  border-right: 4px solid transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.report_class_filter_container > .active::before {
  content: "";
  position: relative;
  border-right: 4px solid var(--app-blue);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.report_class_button {
  padding: 10px 30px;
  text-align: start;
  flex: 1;
  color: var(--text-primary);
  font-weight: 500;
}

.report_class_button:hover {
  background-color: #efefef;
}

.active {
  color: var(--app-blue);
}
