.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.information_header {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px 10px;
}

.information_title_container {
  padding: 5px 10px;
  align-items: center;
}

.information_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
}

.information_title_status {
  margin-left: 10px;
  padding: 0px 15px;
  border-radius: 20px;
  background-color: var(--status-yellow);
  color: white;
  font-weight: bold;
}

.list_container {
  padding: 10px 20px;
}
