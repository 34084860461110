.root_container {
  display: flex;
  flex-direction: column;
}

.nested_menu_group_container {
  background-color: #f8f8f8;
  padding-left: 5px;
}

.menu_tab {
  width: 100%;
}

.tab_style_collapsed {
  margin-left: 10px;
}
