.root {
  display: flex;
  align-items: center;
}

.ol {
  font-size: 16px;
  justify-content: center;
}

.breadcrumbs_item {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--app-blue);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
}

.breadcrumbs_item:hover {
  text-decoration: underline;
}

.breadcrumbs_item:disabled {
  cursor: none;
}

.breadcrumbs_item_last {
  color: var(--text-primary);
}

.icon_clickable {
  color: var(--app-blue);
  cursor: pointer;
}

.item_type_link {
  color: var(--app-blue);
  cursor: pointer;
  margin-left: 0.1em;
  margin-bottom: 0.05em;
}

.item_detail_container {
  font-size: 0.9em;
}

.item_container {
  flex-direction: row;
  display: flex;
}

.home_icon {
  color: var(--app-blue);
  font-size: 24px;
  padding-bottom: 2px;
  cursor: pointer;
}

.home_icon_inactive {
  color: var(--text-primary);
  font-size: 24px;
  padding-bottom: 2px;
  cursor: pointer;
}