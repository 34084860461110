.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.paginated_list_table_loading_container {
  padding: 0px 20px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
}

.table_column {
  flex: 1;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 5px;
}

.table_column:first-child {
  padding-left: 20px;
}
