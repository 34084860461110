.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_column {
  flex: 1;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 5px;
}

.table_column_status {
  flex: 1.5;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  gap: 10px;
}

.table_item_text {
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.table_item_text_active {
  color: var(--app-blue);
  cursor: pointer;
}

.table_item_text_active:hover {
  text-decoration: underline;
  color: var(--app-blue);
}
