.input_text_enabled {
  text-align: left;
  color: black;
}

.input_text_disabled {
  text-align: left;
  color: gray;
}

.margin_maker {
  margin-top: 10%;
}

.md-form {
  font-weight: bold;
}

.user_profile_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding: 16px;
  border-top: 0.5em solid var(--xemelgo-blue);
  border-radius: 0.3em;
}

.swap_button {
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}

.profile_form_buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 0.3em;
}

.change_password {
  color: var(--app-blue);
  margin-top: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.change_password_title {
  text-align: left;
  padding-bottom: 0em;
  font-weight: bold;
}

.input_password {
  text-align: center;
  color: var(--app-lightblack);
}

.input_password:focus {
  color: var(--app-lightblack);
}

.change_password_btn {
  width: 40%;
}

.change_password_alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.change_password_alerts > h4 {
  text-align: left;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.change_password_alerts > p {
  margin-bottom: 2px;
  padding-top: 3px;
}

.show_password_toggle_icon {
  color: var(--app-lightblack);
}

.show_password_toggle_label {
  color: var(--app-lightblack);
  margin-left: 0.6em;
}

.password_input {
  color: var(--app-lightblack);
}

.password_requirement_label_color {
  color: var(--app-lightblack);
}

input:disabled {
  border-bottom: none !important;
}

input[type="file"] {
  border: none;
}

#givenNameInput > .md-form {
  display: inline-block;
}

.responsive_container {
  margin: 0 auto;
  background-color: white;
}

.responsive_container img {
  border-radius: 0.25rem;
  width: 100%;
}

.main_card {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
}

@media only screen and (min-width: 768px) {
  .responsive_container {
    min-width: 60%;
    /* cap container min width */
    max-width: 60%;
    /* cap container max width */
    min-height: 60%;
    max-height: 60%;
    position: relative;
    overflow: hidden;
  }
  .responsive_container:before {
    display: block;
  }

  .responsive_container img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .margin_maker {
    margin-top: 8%;
  }
}
