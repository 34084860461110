.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
}

.side_column {
  width: 250px;
  border-right: 2px solid var(--app-border-grey);
}

.side_column > div:not(:last-child) {
  border-bottom: 2px solid var(--app-border-grey);
}

.buttons_container {
  justify-content: center;
  gap: 10px;
  padding: 15px;
}

.side_filter_container {
  padding: 15px;
}

.content {
  flex: 5;
  padding: 15px;
  gap: 20px;
  padding-bottom: 60px;
}

.filter_bar_group {
  align-items: center;
  gap: 20px;
}

.free_text_search_container {
  flex: 1;
}

.full_screen_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: var(--app-white);
  border: 1px solid transparent;
}

.full_screen_button:hover,
.full_screen_button:active {
  border: 1px solid var(--app-border-grey);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}

.full_screen_button:active {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.export_button,
.export_button:hover {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--app-blue);
  border-radius: 3px;
  color: var(--app-white);
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  gap: 10px;
  height: 35px;
}

.export_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.export_button:active {
  opacity: 0.9;
}

.tabs_group {
  gap: 10px;
  color: var(--app-white);
}

.tab {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--app-white);
  border: 1px solid var(--app-blue);
  border-radius: 8px;
  color: var(--app-blue) !important;
  font-weight: 500;
  font-size: 16px;
  gap: 10px;
  padding: 8px 20px;
}

.tab_active {
  color: var(--app-white) !important;
  background-color: var(--app-blue);
}

.tab:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.tab:active {
  opacity: 0.8;
}

.title_right_container {
  gap: 30px;
  justify-content: center;
}

.last_updated_time_container {
  gap: 5px;
  justify-content: center;
  margin-right: 20px;
}

.last_updated_time_text {
  font-weight: 500;
}
