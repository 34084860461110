button {
  background: none;
  border: none;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.title_right_container {
  gap: 40px;
  margin-right: 20px;
  align-items: flex-end;
}

.title_right_reload_button {
  justify-content: flex-end;
  align-items: center;
  color: var(--app-blue);
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
}

.title_right_reload_button:hover {
  background-color: var(--app-offwhite);
  border-color: var(--app-border-grey);
}

.map_control {
  right: 650px !important;
}

.map_control_collapsed {
  right: 0px !important;
}

.map_zoom_control {
  flex-direction: column;
  display: flex;
  bottom: 0px !important;
  top: auto !important;
  right: 650px !important;
  margin-bottom: 10px;
}

.map_zoom_control_collapsed {
  right: 50px !important;
}

.map_color_indicator {
  margin-left: 10px;
  margin-bottom: 35px !important;
  left: 0px !important;
  right: auto !important;
}

.top_left_action_container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  gap: 10px;
}

.top_left_action {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.top_right_action_container {
  position: absolute;
  top: 10px;
  margin-right: 10px;
  right: 650px;
  z-index: 2;
  gap: 10px;
  transition: 500ms ease-in-out;
}

.top_right_action_container_collapsed {
  right: 0px !important;
}

.top_right_action {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.view_mode_container {
  width: 200px;
}
