.picker_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.picker_container > p {
  color: var(--text-primary);
  font-weight: 500;
}

.type_button {
  background-color: white;
  color: var(--text-primary);
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
}

.type_button:hover {
  background-color: #efefef;
}

.type_button_selected {
  background-color: var(--app-blue);
  color: white;
  border-color: var(--app-blue);
}

.type_button_selected:hover {
  background-color: var(--app-blue);
}
