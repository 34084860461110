.outer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.outer_container > .width {
  width: 600px;
}
