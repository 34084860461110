.grid_card_container_style {
  width: 90%;
  margin: auto;
  padding: 10px;
  border-top: 10px solid white;
  background-color: white;
  border-radius: 5px;
  border: 2px solid var(--app-border-grey);
  color: black;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.title_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  display: inline-flex;
  margin-left: 37%;
}

.name_container {
  justify-content: center;
  display: flex;
}

.image {
  width: 100%;
  justify-self: center;
  align-self: center;
}

.identifier_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 35px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--app-border-grey);
}

.identifier_label {
  font-size: 18px;
  font-weight: normal;
  text-align: right;
  margin-right: 5px;
  flex: 1
}

.identifier_text {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  flex: 1
}

.horizontal_split {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.details_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  margin-top: 20px;
}

.details_label {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  flex: 1
}

.details_text {
  font-weight: normal;
  margin-left: 10px;
  text-align: left;
  flex: 1
}

.status {
  font-size: 12px;
  width:fit-content;
  padding: 1px 10px 0px 10px;
  border-radius: 20px;
  color: var(--app-white);
  margin-left: 10px;
}

.status_text {
  margin-top: 0.1em;
  font-weight: 600;
}
