.list_title {
  margin-bottom: 0.25rem;
}

.list_scroll {
  max-height: 200px;
  overflow-y: scroll;
}

.list_row {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.list_row_underline {
  border-bottom: 1px solid var(--app-border-grey);
}

.list_element {
  padding-left: 10px;
  width: 48%;
  text-align: left;
}
