.home_icon_container {
  border-bottom: 1px solid #d3d3d3;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_icon_container > img {
  max-width: 100%;
  min-width: 130px;
  max-height: 100%;
}

.home_image_style {
  width: 130px;
  height: 60px;
}

.search_bar_container {
  border-bottom: solid;
  border-width: 1px;
  border-color: var(--app-border-grey);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 0.85;
}

.search_bar {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.user_actions_container {
  border-bottom: solid;
  border-width: 1px;
  border-color: var(--app-border-grey);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: 0.15;
  padding: 0em 1em 0em 1em;
}

.user_actions {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile,
.profile:hover {
  color: grey;
}

.profile:after {
  margin-left: 0.75em;
  vertical-align: middle;
  display: inline-block;
}

.profile_name {
  display: inline-block;
  color: black;
}

.logout {
  color: red;
}

.main_container_testing {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background-color: white;
  z-index: 1;
}

.main_container {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background-color: white;
  z-index: 1;
}

.testing_container {
  display: flex;
  flex-direction: row;
  background-color: #bdd797;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100%;
}

.tooltip_container {
  width: 180px;
  height: 150px;
  font-size: 1.2em;
  padding: 6px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.help_icon {
  width: 23px;
  height: 23px;
  margin-top: 5.5px;
  cursor: pointer;
}

.help_button {
  width: 34px;
  height: 34px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.help_button:hover {
  background-color: var(--app-offwhite);
}

