.modal_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
  gap: 5px;
  padding-bottom: 20px;
}

.description_text {
  font-size: 18px;
  color: var(--app-lightgrey);
  font-weight: 500;
}

.list_item {
  position: relative;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  text-align: left;
}

.list_item_selected,
.list_item:hover {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.list_item:active {
  opacity: 0.8;
}

/* this is list separator */
.list_item:not(:last-child)::after {
  content: "";
  width: 100%;
  left: 0px;
  position: absolute;
  height: 1px;
  background-color: var(--app-border-grey);
  bottom: -3px;
}

.add_button {
  background-color: var(--app-blue);
  color: var(--app-white) !important;
}
