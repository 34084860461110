.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.skeleton_header {
  margin-top: 10px;
}

.main_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
}

.side_container {
  border-right: 1px solid var(--app-border-grey);
}

.feature_buttons {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px;
}

.side_filter_container {
  padding: 15px;
  width: 200px;
}

.filter_title_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.hide_filter {
  font-size: 12px;
  font-weight: 400;
  color: var(--app-blue);
  cursor: pointer;
}

.hide_filter:hover {
  text-decoration: underline;
}

.groupby_label {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.date_filter_container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.date_filter_title {
  font-size: 12px;
  font-weight: 500px;
  color: #6c757d;
}
.date_filter {
  min-width: 0px;
}

.date_filter_input {
  width: 170px;
  height: 30px;
  font-size: 12px;
}

.informational_container {
  flex: 1;
}

.data_container {
  display: flex;
  flex: 1;
  padding: 15px;
  flex-direction: column;
}

.search_and_buttons_group {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.buttons_container {
  margin: 5px 0px 5px 0px;
  display: flex;
  flex-direction: row;
}

.buttons_group {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px;
}

.buttons_group_container {
  height: 10%;
  width: 100%;
  background-color: azure;
}

.create_button {
  font-weight: 400;
  letter-spacing: 0.02rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 14px;
  text-transform: none;
}

.create_button:hover {
  color: white;
  box-shadow: 0px 3px 9px 0px slategrey;
}

.view_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.view_label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  padding-right: 20px;
}

.view_btn {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  background-color: white;
  white-space: nowrap;
}

.view_btn_active {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: var(--order-primary);
}

.all_accordion_btns {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.sort_header_btn {
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
  color: var(--app-blue);
}

.sort_header_btn:hover {
  text-decoration: underline;
}

.data_container {
  display: flex;
  flex: 1;
  padding: 15px;
  flex-direction: column;
}

.no_data {
  display: flex;
  flex: 1;
  height: 250px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #6c757d;
}

.table_column {
  flex: 1;
}
