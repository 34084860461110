.flex_column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons_group {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  padding: 10px 0px 20px 0px;
}

.clickable_text {
  justify-self: flex-end;
  padding: 0px;
  color: var(--app-blue);
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
}
