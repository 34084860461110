.tab_list_style {
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tab_style {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: Black;
  font-size: 0.9em;
  margin-top: 2%;
  width: 90%;
}

.tab_style:focus {
  outline: none;
}

.tab_style:hover {
  background-color: var(--app-offwhite);
  color: black;
}

.focused_tab_style {
  background-color: var(--app-blue);
  color: white;
}

.groupby_label {
  font-weight: bold;
  color: #757575;
}
