.onboard-modal-body {
  padding: 0px;
  width: 100%;
}

.onboard-modal-title {
  width: 100%;
}

.onboard-modal-header {
  margin: 0px !important;
  padding: 0px;
}

.onboard-modal-footer {
  border-top: 1.6px solid var(--app-border-grey);
  padding: 0px;
  background: var(--app-white);
}
