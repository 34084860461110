.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.total_count_container {
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  align-items: baseline;
}

.total_count_text {
  letter-spacing: 0.5px;
  font-size: 14px;
}

.total_count_value {
  font-weight: bold;
  font-size: 16px;
}

.information_status_tile_list {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.information_table {
  flex: 1;
}
