.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
}

.side_column {
  width: 250px;
  border-right: 2px solid var(--app-border-grey);
}

.side_column > div:not(:last-child) {
  border-bottom: 2px solid var(--app-border-grey);
}

.side_filter_container {
  padding: 15px;
}

.content {
  flex: 5;
  padding: 15px;
  gap: 20px;
  padding-bottom: 60px;
}

.filter_bar_group {
  align-items: center;
  gap: 20px;
}

.free_text_search_container {
  flex: 1;
}

.export_button,
.export_button:hover {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--inventory-primary);
  border-radius: 3px;
  color: white;
  padding: 10px;
  font-weight: 500;
  gap: 10px;
}

.export_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.export_button:active {
  opacity: 0.9;
}

.loading_circle_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
}
