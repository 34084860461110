.filter_tab_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter_tab_label {
  font-size: 12px;
  color: var(--text-primary);
  padding-top: 5px;
  padding-right: 5px;
  font-weight: 500;
  margin: 5px;
}

.filter_tab_list {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.filter_tab_item_group {
  padding-left: 10px;
  padding-right: 5px;
  margin: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 1.8em;
  background-color: var(--xemelgo-light-blue);
}

.filter_tab_item_title {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
  padding-right: 15px;
}

.filter_tab_item_close_icon {
  cursor: pointer;
  width: 15px !important;
  margin-left: auto;
  font-size: 12px;
  color: var(--app-blue);
  font-weight: 500;
}

.filter_tab_more_count {
  font-size: 12px;
  margin: 5[x];
  color: var(--text-primary);
  font-weight: 500;
}

.filter_action_btn {
  cursor: pointer;
  font-size: 12px;
  margin: 5px;
  color: var(--app-blue);
  font-weight: 500;
}

.close_button {
  color: var(--app-blue);
  cursor: pointer; 
}