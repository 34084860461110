.actions_container {
  display: flex;
}
.grid_card_container_style {
  min-width: 400px;
  min-height: 220px;
  margin: 10px;
}

.filter_bar {
  height: 40px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  padding-left: 1em;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
}

.display_timer {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;
  padding: 3px;
  align-self: center;
}

.icon_button:hover {
  color: white;
  background-color: var(--app-blue);
}

.multi_select_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.multi_select_text_container {
  display: flex;
  flex-direction: row;
  width: 25%;
  padding: calc(0.6rem + 10px) 1rem 0.6rem;
}

.multi_select_count_container {
  display: flex;
  flex-direction: row;
}

.multi_select_count_text {
  font-weight: bold;
  margin-right: 0.2em;
}

.multi_select_deselect_text {
  color: var(--app-blue);
  margin-left: 5%;
  cursor: pointer;
}

.multi_select_edit {
  color: var(--app-blue);
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.skeleton_cols {
  margin-left: 15px;
  margin-right: 15px;
}

.skeleton_row {
  height: 55px;
  border-style: solid;
  border-color: var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right: none;
  border-left: none;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.skeleton_group {
  margin-top: 15px;
}