.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  margin: 2px 15px;
}

.main_container {
  justify-content: space-between;
}

.flex_column {
  display: "flex";
  flex-direction: column;
}

.icon_container {
  justify-self: center;
}

.closeButton {
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 0.7;
}

.closeButton:hover {
  opacity: 1.0;
}